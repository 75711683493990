import React, { Component } from "react";
import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { MDBContainer } from "mdbreact";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

class RtiDescription extends Component {
  render() {
    return (
      <div style={{ marginTop: "" }}>
        {this.props.type == "home" ? (
          <History title="RIGHT TO INFORMATION" />
        ) : null}
      </div>
    );
  }
}

export default RtiDescription;

export const History = (props) => {
  return (
    <div>
      <BreadCrumbOwn title="Right to Information" />
      <Container className="mb-5">
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700, textAlign: "center", color: 'red' }}
          gutterBottom
        >
          Statutory declaration under section 4(1) (b) of the RTI Act, 2005
          <br />
          Particulars of the Institution
        </Typography>

        <Table size="small" border={1}>
          <TableBody>
            <TableRow>
              <TableCell style={{fontWeight: 700}}>Name of the Institution</TableCell>
              <TableCell style={{fontWeight: 700}}>
                Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM),
                Eraligool (Higher Education Institution)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Year of Establishment</TableCell>
              <TableCell>2017</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Postal Address</TableCell>
              <TableCell>PDUAM, Eraligool, PO: Eraligool-788723</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Telephone Number</TableCell>
              <TableCell>8011576322</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email Id</TableCell>
              <TableCell>principal.eraligool@gmail.com</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Website</TableCell>
              <TableCell>
                Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM) (
                <a href="https://pduameraligool.ac.in">pduameraligool.ac.in</a>)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Nature of the Institution</TableCell>
              <TableCell>Government</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Institutional Affiliation</TableCell>
              <TableCell>
                Affiliated to Assam University, Silchar, Assam
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Streams</TableCell>
              <TableCell>Arts & Science</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Number of Departments</TableCell>
              <TableCell>13</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Courses offered</TableCell>
              <TableCell>B.A., B.Sc.</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Principal</TableCell>
              <TableCell>Dr. Jayasree Chakrabarty</TableCell>
            </TableRow>
          </TableBody>
        </Table>
                  <br />
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          Section 4 (1) (b) of the RTI Act, 2005 covers the statutory
          declaration of PDUAM, Eraligool (Website: pduameraligool.ac.in). The
          Mahavidyalaya runs as per the rules and regulations made by the
          Department of Higher Education, Government of Assam from time to time.
        </Typography>
        <br />
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          There is a Governing Body headed by the President, which looks after
          the management of the Mahavidyalaya. However, the administrative head
          of the institution is Principal. The Mahavidyalaya has different
          Cells/Committees to maintain all types of disciplines in the campus.
        </Typography>
        <br />
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Functions and Duties of the Mahavidyalaya
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya(PDUAM), Eraligool is
          a premier higher education institution committed to delivering quality
          education for all. The Mahavidyalaya is affiliated to Assam University
          and it adheres to all the academic curricula of the University at UG
          level. All the faculty members perform their teaching duties and other
          responsibilities with due diligence as per the norms prescribed in the
          UGC regulations.
        </Typography>

        <br />
        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Right to Information (RTI) and Information Delivery System of the
          Mahavidyalaya:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          PDUAM, Eraligool, being a Government funded public institution, is
          subject to RTI Act, 2005. To ensure transparency and accountability,
          the Mahavidyalaya has put in place a proper information delivery
          mechanism. One senior most faculty of the Mahavidyalaya acts as the
          Public Information Officer (PIO) of the Mahavidyalaya. The PIO
          receives all types of RTI applications. On receipt of an RTI
          application, the PIO registers the matter in the RTI log book and
          seeks information from the concerned Department or section in the
          Mahavidyalaya, as the case may be. The PIO ensures the timely reply of
          the RTI queries. The Mahavidyalaya has so far received and replied
          satisfactorily to several such queries within the stipulated time.
        </Typography>
        <br />
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          The application under the RTI Act along with a Postal Order/Demand
          Draft for Rs. 10 (Rupees Ten) only obtained in favour of the
          Principal, PDUAM, Eraligool, or may be sent to the PIO or handed over
          in the office of the Mahavidyalaya. All the information about the
          Mahavidyalaya, under 4 (1) (b) of the RTI act, 2005, are open for the
          public and it can be obtained by a citizen of India through the
          Mahavidyalaya’s website.
        </Typography>
        <br />
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          For further information, please contact @ 9101792014.
        </Typography>

        <br />
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          The RTI Cell is constituted with the following members.
        </Typography>

        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Appellate Authority
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          Dr. Jayasree Chakrabarty<br />
          Designation: Principal, PDUAM, Eraligool, Sribhumi<br />
          Phone No: <a href="tel:9435179096">9435179096</a>, E-mail: <a href="mailto:principal.eraligool@gmail.com">principal.eraligool@gmail.com</a>
        </Typography>

        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Public Information Officer (PIO)
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          Dr. Surajit Ray<br />
          Designation: Asst. Prof. & Head, Dept. of English, PDUAM, Eraligool, Sribhumi<br />
          Phone No: <a href="tel:9101792014">9101792014</a>, E-mail: <a href="mailto:raysurajit124@gmail.com">raysurajit124@gmail.com</a>
        </Typography>

        <Typography
          variant="h5"
          style={{ margin: "1em 0", fontWeight: 700 }}
          gutterBottom
        >
          Assistant Public Information Officer (PIO)
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          style={{ textAlign: "justify" }}
        >
          Dr. Kaustav Dutta<br />
          Designation: Asst. Prof., Dept. of Statistics, PDUAM, Eraligool, Sribhumi<br />
          Phone No: <a href="tel:7002399819">7002399819</a>, E-mail: <a href="mailto:kaustav0708@gmail.com">kaustav0708@gmail.com</a>
        </Typography>
      </Container>
    </div>
  );
};
