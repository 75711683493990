import React, { Component } from "react";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  Button,
} from "mdbreact";
import { Card, CardHeader } from "reactstrap";
import BreadCumb from "../Department/BreadCumb";
import { PostData } from "../../api/service";
import { Container, Typography } from "@material-ui/core";
import BreadCrumbOwn from "../Essentials/BreadCrumbOwn";

// const facultyData = [
//     {
//         name: 'Dr. Jogesh Kakati',
//         desg: 'Principal',
//         qualification: 'MA, PhD',
//         email: '',
//         phone: '',
//         mobile: '',
//         specifications: '',
//         cv: '',
//         photo: ''
//     },

// ]

export default class PrincipalContainer extends Component {
  state = {
    facultyData: [],
    isLoaded: false,
    dept_code: "OFF",
  };

  componentDidMount() {
    let d = {
      dept_code: "OFF",
      type: "PRINCIPAL",
    };

    PostData(`/getemployeebytype`, d).then((resp) => {
      //console.log(resp)
      this.setState({
        ...resp[0],
        facultyData: resp,
        isLoaded: true,
      });
    });
  }
  render() {
    return (
      <div>
        <BreadCrumbOwn title="Principal's Message" />

        <Container className="mb-5">
          <br />

          {this.state.isLoaded &&
            this.state.facultyData.map((el, index) => {
              return (
                <div>
                  {/* <Card style={{ boxShadow: 'none' }}>
                            <CardHeader style={{ color: 'white', boxShadow: 'none', backgroundColor: '#1E3760', textAlign: 'center' }}> <h3 style={{color: 'inherit'}}>{el.desg}</h3></CardHeader>
                        </Card> */}

                  <div>
                  <div>
                        <div style={{ textAlign: "left" }}>
                          <img
                            alt=""
                            src={el.dp.length > 0 ? el.dp[0] : ""}
                            style={{
                              width: "350px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div>

                        {/* <h5
                          style={{
                            fontSize: "16px",
                            textAlign: "right",
                            fontWeight: 700,
                          }}
                        >
                          {el.name}<br />
                          Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya<br />
						               Eraligool, Sribhumi
                        </h5> */}
                       
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          {/* <a href={`tel:${el.mobile}`}>
                            {this.state.isLoaded && this.state.id != undefined && (
                              <a
                                style={{
                                  color: "#1892FF",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  textAlign: "right",
                                }}
                                // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                href={el.cv.length > 0 ? el.cv[0] : ""}
                              >
                                Visit Profile
                              </a>
                            )}
                          </a> */}
                        </h6>
                      </div>
                    <div>
                      <Typography
                        variant="h5"
                        style={{ margin: "1em 0", fontWeight: 700 }}
                        gutterBottom
                      >
                        WELCOME MESSAGE FROM THE PRINCIPAL
                      </Typography>
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >
                        I am pleased to announce the commencement of a new academic session 2024-2025 at Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya (PDUAM), Eraligool, Sribhumi. Through collaboration with various stakeholders of the Mahavidyalaya, we have effectively concluded all preceding academic sessions. I extend a warm welcome to all the eager students of the Mahavidyalaya and express gratitude to their parents and guardians for choosing this institution for their children's higher education. The establishment of the Mahavidyalaya dates back to 2017, marking it as the inaugural Government Science Model Degree College in the Barak Valley. This institution stands as a visionary initiative of the Government of Assam, financially supported by the Rashtriya Uchchatar Shiksha Abhiyan (RUSA).
                      </Typography>

                      <br />

                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >
                        The Mahavidyalaya commenced its maiden academic journey from the academic session 2017-2018, offering Higher Secondary (10+2) courses in Science under Assam Higher Secondary Education Council, Bamunimaidam, Guwahati, and Under-graduate (UG) Science programmes leading to Bachelor of Science (B.Sc.) under Assam University, Silchar. Now, the Mahavidyalaya has emerged as a truly multidisciplinary higher education institution by offering Four-Year Under-graduate (FYUG) Programmes in both Arts and Science streams based on the NEP 2020 curriculum, under Assam University, Silchar, from the academic session 2023-2024. While the FYUG Programmes in Science are offered in six subjects, such as Physics, Chemistry, Mathematics, Botany, Zoology, Statistics; the FYUG Programmes in Arts are offered in seven subjects that include English, Bengali, Economics, Education, Sociology, Political Science, and History. It is to be mentioned here that the FYUG Programmes in Arts were introduced at the Mahavidyalaya from the academic session 2023-2024.
                      </Typography>

                      <br/>
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >In order to provide high-quality Arts and Science education at the UG level, the Mahavidyalaya has an appropriate infrastructure in place, including academic buildings and laboratories. Various workshops focusing on different aspects of Arts and Science education and research, as well as numerous co-curricular activities, are regularly organized on the days of national significance. The Mahavidyalaya has successfully completed infrastructure projects such as the construction of girls’ hostel, boundary walls, and residential complexes for Staff and Sports buildings. Plans are underway to establish a gymnasium for the fitness needs of both the students and employees. Further infrastructure development includes the construction of a Canteen and an academic annex building. The Mahavidyalaya is currently making preparations for the first cycle of visit by the National Assessment and Accreditation Council (NAAC) during the current academic session.</Typography>
                      <br />
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >I express my gratitude to the Hon’ble the then District Commissioner, Sribhumi cum Founder President, Governing Body of our Mahavidyalaya, Mr. P. K. Talukdar , ACS, for allocating an additional five bighas of land for the Mahavidyalaya's playground. PDUAM, Eraligool is dedicated to providing skill-based knowledge, fostering inclusive education, promoting participatory culture, and instilling a value-oriented approach alongside its regular courses, aligning with the NEP 2020 of the Government of India. The Mahavidyalaya is committed to advancing its academic dimensions through high-quality teaching, innovative pedagogical techniques, and research to cater to the requirements of Arts and Science education. It is with great privilege that I announce the presence of well-qualified, experienced, and enthusiastic teachers who are devoted to their vocation. This educational institution will be instrumental in enabling students to attain academic and intellectual achievements.</Typography>


                      <br />

                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >On behalf of the PDUAM, Eraligool family, I wish to extend my heartfelt  thanks and appreciation to Dr. Himanta Biswa Sarma, the esteemed Chief Minister of Assam, Dr. Ranoj Pegu, the Hon’ble Minister of Education, Tribal Welfare, Indigenous & Tribal Faith & Culture Department, Assam,  Sri Parimal Suklabaidya, the Hon’ble Minister of Transport, Fishery and Excise, Assam, Sri Kripanath Mallah, the Hon’ble Member of Parliament, Sribhumi Lok Sabha Constituency, and all the Members of Legislative Assembly (MLAs) of Sribhumi district for their unwavering assistance and backing in developing top-notch infrastructure of this Mahavidyalaya in this remote area of Assam. This is also an apt moment to convey my appreciation to Shri Narayan Konwar, IAS, the Hon’ble Secretary of Higher Education, Govt of Assam and his committed team for their consistent support and presence, as well as Smti Pomi Barua, ACS, the Hon’ble Director of Higher Education, Government of Assam and her dedicated team.</Typography>

                      <br />
                      <Typography
                        style={{ textAlign: "justify" }}
                        variant="body1"
                        gutterBottom
                      >In this connection, I would like to take the opportunity to put on record the outstanding guidance, I have received from the Hon’ble District Commissioner of Sribhumi district cum President, Governing Body of this Mahavidyalaya in running this newly established educational institution. I also express my gratitude to his entire office staff for their unwavering support in advancing the academic mission of this Mahavidyalaya. Recognition is due to both my teaching and non-teaching staff, to whom I express my sincere appreciation for their tireless dedication in steering the institution in the right direction. Conclusively, I express my gratefulness to all the parents and guardians for choosing this Mahavidyalaya for education of their wards. I extend a warm welcome to all the fresh students who have taken admission, or planning to enrol in our Mahavidyalaya.  After successfully completing their academic journey, I believe our students will realize the education imparted by our institution as truly valuable for their lifelong pursuits.</Typography>
                      <br />

                      <div>
                        {/* <div style={{ textAlign: "right" }}>
                          <img
                            alt=""
                            src={el.dp.length > 0 ? el.dp[0] : ""}
                            style={{
                              width: "250px",
                              boxShadow: "1px 2px 10px rgba(0,0,0,0.1)",
                              border: `solid 5px wheat`,
                            }}
                          />
                        </div> */}

                        <h4
                          style={{
                            fontSize: "20px",
                            textAlign: "right",
                            fontWeight: 700,
                          }}
                        >
                          {el.name}<br />
                          Pandit Deendayal Upadhyaya Adarsha Mahavidyalaya<br />
						               Eraligool, Sribhumi
                        </h4>
                       
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`mailto:${el.email}`}>{el.email}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.phone}`}>{el.phone}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>{el.mobile}</a>
                        </h6>
                        <h6 style={{ fontSize: "12px", textAlign: "right" }}>
                          <a href={`tel:${el.mobile}`}>
                            {this.state.isLoaded && this.state.id != undefined && (
                              <a
                                style={{
                                  color: "#1892FF",
                                  cursor: "pointer",
                                  fontWeight: 700,
                                  textAlign: "right",
                                }}
                                // href={`/department/${this.state.dept_code}/faculty/single?id=${this.state.id}&name=${this.state.name}`}
                                href={el.cv.length > 0 ? el.cv[0] : ""}
                              >
                                Visit Profile
                              </a>
                            )}
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Container>
      </div>
    );
  }
}
